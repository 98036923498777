import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ArrowRightStartOnRectangleIcon,
  ClipboardDocumentListIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as People } from "../assets/icons/people.svg";
import { useEffect, useState } from "react";
import { useCustomerContext } from "../contexts/CustomerContext";
import { comboPackagesList, packages } from "../constants/packages";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { routes } from "../constants/routes";

const Menu = ({ onClose, onLogout }: { onClose?: any; onLogout?: any }) => {
  const askDotUrl = process.env.REACT_APP_ASKDOT_URL || "";
  const { pathname } = useLocation();
  const { feature } = useCustomerContext();
  const [showFeatures, setShowFeatures] = useState<string[]>([]);

  useEffect(() => {
    switch (feature) {
      case comboPackagesList.ultimate:
        setShowFeatures([
          packages.peopleai,
          packages.crosscite,
          packages.askdot,
        ]);
        break;
      case comboPackagesList.premium:
        setShowFeatures([
          packages.crosscite,
          packages.askdot,
          packages.peopleai,
        ]);
        break;
      case comboPackagesList.foundation:
      case packages.askdot:
        setShowFeatures([packages.askdot, packages.peopleai]);
        break;
      default:
        break;
    }
  }, [feature]);

  const isCrossCiteAvailable = showFeatures.includes(packages.crosscite);
  const isAskdotAvailable = showFeatures.includes(packages.askdot);

  const chooseClassName = (
    isActive: boolean,
    isFeatureAvailable: boolean
  ): string => {
    let clsName = "";
    if (isFeatureAvailable) {
      if (isActive) {
        clsName = "menu-link-active";
      } else {
        clsName = "menu-link";
      }
    } else {
      clsName = "menu-link no-interaction";
    }
    return clsName;
  };
  return (
    <>
      <NavLink
        to={routes.projects}
        className={({ isActive }) =>
          isActive && pathname === routes.projects
            ? "menu-link-active"
            : "menu-link"
        }
        onClick={onClose}
      >
        <ClipboardDocumentListIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Projects
      </NavLink>

      <NavLink
        to={routes.peopleai}
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <div className="flex items-center">
          <span className="mr-3 h-8 w-8 flex-shrink-0 people">
            <People />
          </span>
          <span>People+AI</span>
        </div>
      </NavLink>

      <NavLink
        to={isCrossCiteAvailable ? routes.crosscite : "#"}
        className={({ isActive }) =>
          chooseClassName(isActive, isCrossCiteAvailable)
        }
        onClick={onClose}
      >
        <div
          {...(!isCrossCiteAvailable && {
            "data-tooltip-id": "subscription-tip",
          })}
          className="flex items-center"
        >
          <img
            alt="Crosscite"
            src="/crosscite.png"
            className="mr-3 h-8 w-8 flex-shrink-0 opacity-70"
          />
          <span>CrossCite</span>
        </div>
      </NavLink>

      <a
        href={isAskdotAvailable ? askDotUrl : "#"}
        className={isAskdotAvailable ? "menu-link" : "menu-link no-interaction"}
        {...(isAskdotAvailable ? { target: "_blank" } : {})}
        rel="noreferrer"
      >
        <div
          {...(!isAskdotAvailable && {
            "data-tooltip-id": "subscription-tip",
          })}
          className="flex items-center"
        >
          <img
            alt="Askdot"
            src="/askdot.ico"
            className="mr-3 h-8 w-8 flex-shrink-0 opacity-70"
          />
          <span>AskDot</span>
        </div>
      </a>

      <NavLink
        to={routes.help}
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <QuestionMarkCircleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Help
      </NavLink>
      <span className="menu-link cursor-pointer" onClick={onLogout}>
        <ArrowRightStartOnRectangleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Logout
      </span>
      <ReactTooltip
        id="subscription-tip"
        clickable
        style={{
          backgroundColor: "black",
          color: "white",
          zIndex: 999,
          borderRadius: "5px",
          padding: "15px",
          width: 250,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: 1.4,
          textAlign: "left",
        }}
        className="md:max-w-sm tooltip-mobile !font-montserrat"
        place="bottom-start"
        opacity={1}
      >
        To use this feature, you need to purchase a{" "}
        <Link to={routes.subscriptions} className="font-bold underline">
          subscription
        </Link>
      </ReactTooltip>
    </>
  );
};

export default Menu;
