export const searchTypes = ["novelty", "freedom"];
export const helpCenterLink = "https://dorothyai.helpdocsonline.com/home";

export const doNotRefreshPage =
  'Please do not refresh the page or click the "Back" button of your browser';

export const weightOptions = [
  { value: 0.1, label: "Low", color: "text-blue-600" },
  { value: 0.5, label: "Medium", color: "text-yellow-600" },
  { value: 1.0, label: "High", color: "text-error" },
];

export const strategicAlignmentNovelWeightOptions = [
  { value: "Low", label: "Low", color: "text-blue-600" },
  { value: "Medium", label: "Medium", color: "text-yellow-600" },
  { value: "High", label: "High", color: "text-error" },
];

export const dateTypes = [
  { value: "priority_date", label: "Priority / Application" },
  { value: "publication_date", label: "Publication" },
  { value: "issue_date", label: "Issue / Grant" },
];

export const validationMessages = {
  novelty: {
    abstract: "Enter or paste the abstract for your invention. Required",
    features: "The distinctive that you wish to patent. Required",
  },
  freedom: {
    elements: "This field is required.",
  },
};

export const dateFormatOptions: any = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
