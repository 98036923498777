import { useState } from "react";
import { CROSSCITE_URL } from "../constants/urls";
import { Loader } from "../components/shared";
import { useKeycloak } from "@react-keycloak/web";

const CrossCite = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { keycloak } = useKeycloak();

  const iframeSrc = keycloak?.token
    ? `${CROSSCITE_URL}?token=${keycloak?.token}`
    : CROSSCITE_URL;
  return (
    <>
      {loading && <Loader />}
      <iframe
        src={iframeSrc}
        style={{
          width: "100%",
          height: "calc(100vh - 113px)",
        }}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default CrossCite;
