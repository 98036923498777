const OverlayLoader: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex flex-col gap-y-6 items-center justify-center z-50">
      <div className="w-12 h-12 border-4 border-t-transparent border-purple-200 rounded-full animate-spin"></div>
      {!!text && (
        <div className="text-lg text-white/70 max-w-sm text-center">{text}</div>
      )}
    </div>
  );
};

export default OverlayLoader;
