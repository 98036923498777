import { createPortal } from "react-dom";

const Loader = () => {
  const loader = (
    <div className="fixed left-0 top-0 h-full w-full z-50 flex justify-center items-center">
      <div className="text-center">
        <svg
          className="w-24 h-24 inline-block"
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
          xmlSpace="preserve"
        >
          <path
            fill="#000"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
        <h1>
          Page content is loading.
          <br />
          Please wait.
        </h1>
      </div>
    </div>
  );
  const container: any = document.getElementById("root-portal");
  return createPortal(loader, container);
};

export default Loader;
